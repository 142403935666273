import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import PaginationNav from './pagination-nav';
import PaginationLinks from './pagination-links';

const useStyles = makeStyles(theme => ({
    container: {
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        padding: theme.spacing(2.5, 2),
        width: `100%`,
        minHeight: `3.2em`,
        position: `relative`,
        backgroundColor: theme.palette.neutral[1100],
        [theme.breakpoints.up(`sm`)]: {
            padding: theme.spacing(3, 3),
        },
        [theme.breakpoints.up(`md`)]: {
            padding: theme.spacing(4, 4),
        },
    },
    pagination: {
        display: `inline-flex`,
        justifyContent: `space-between`,
        alignItems: `center`,
        width: `100%`,
        position: `relative`,
        backgroundColor: theme.palette.common.white,
    }
}));

function Pagination(props) {

    // debugging
    // console.log(`pageContext in pagination`, pageContext);

    // do some destructuring
    const { pageContext } = props;
    const { humanPageNumber, numberOfPages } = pageContext;

    // easier to read
    const isFirstPage = humanPageNumber === 1;
    const isLastPage = humanPageNumber === numberOfPages;

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.pagination}>
                <PaginationNav
                    hidden={isFirstPage}
                    to={pageContext.previousPagePath}>上
                </PaginationNav>

                <PaginationLinks pageContext={pageContext}/>

                <PaginationNav
                    hidden={isLastPage}
                    to={pageContext.nextPagePath}>下
                </PaginationNav>
            </div>
        </div>

    );
}

Pagination.propTypes = {
    pageContext: PropTypes.object.isRequired
};

export default memo(Pagination);