import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    hidden: {
        visibility: `hidden`,
        '&:hover': {
            visibility: `hidden`,
        },
    },
    nav: {
        backgroundColor: theme.palette.neutral[100],
        color: theme.palette.common.white,
        textDecoration: `none`,
        flexShrink: 0,
        fontWeight: 800,
        width: `48px`,
        height: `48px`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,


        [theme.breakpoints.up(`sm`)]: {
            height: `56px`,
            width: `56px`,
        },

        [theme.breakpoints.up(`md`)]:  {
            height: `64px`,
            width: `64px`,
        },
    }
}));

function PaginationNav(props) {

    const { hidden, children, to } = props;
    const classes = useStyles();

    return (
        <Link disabled={hidden} to={to} className={`${classes.nav} ${hidden
            ? classes.hidden
            : ''}
                `}
        >
            {children}
        </Link>
    );
}

PaginationNav.propTypes = {
    hidden: PropTypes.bool,
    children: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
};

export default memo(PaginationNav);