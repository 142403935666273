import React, { Fragment, memo } from 'react';
import { graphql } from 'gatsby';

import PostList from './post-list';
import Pagination from './pagination/pagination';
import PageTitle from './page-title';
import SEO from './seo';


function Category(props) {

    const { pageContext, data } = props;
    const { category, numberOfPages } = pageContext;

    return (
        <Fragment>
            <SEO title={category} keywords={[`${category}`]}/>
            <PageTitle>{`分类：${category}`}</PageTitle>
            <PostList posts={data.allMdx.edges}/>
            {numberOfPages > 1 && <Pagination pageContext={pageContext}/>}
        </Fragment>
    );
}


export const categoryQuery = graphql`
    query categoryQuery($skip: Int!, $limit: Int!, $category: String) {
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            skip: $skip
            limit: $limit
            filter: {frontmatter: {categories: {in: [$category]}}}
        ) {
            edges {
                node {
                    ...post
                }
            }
        }
    }
`;

export default memo(Category);