import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { getPaginationNeighbors } from '../../lib/helpers';

const useStyles = makeStyles(theme => ({
    links: {
        display: `inline-flex`,
        justifyContent: `center`,
        alignItems: `center`,
        width: `100%`,
        fontSize: theme.typeScale[`16px`],
        padding: `0 ${theme.spacing(3)}`,

        [theme.breakpoints.up(`sm`)]: {
            padding: `0 ${theme.spacing(6)}`,
            justifyContent: `space-between`,
        },

        [theme.breakpoints.up(`md`)]: {
            padding: `0 ${theme.spacing(8)}`,
        },

    },

    link: {
        textDecoration: `none`,
        color: theme.palette.primary[500],
        display: `none`,
        cursor: `pointer`,

        "&:hover": {
            textDecoration: `underline`,
        },

        [theme.breakpoints.up(`sm`)]: {
            display: `inline-block`
        },
    },

    currentPage: {
        display: `inline-block`,

        [theme.breakpoints.up(`sm`)]: {
            fontWeight: 800,
        },
    },

    ellipsis: {
        textDecoration: `none`,
        cursor: `arrow`,
        "&:hover": {
            textDecoration: `none`,
        },
    },
}));


function PaginationLinks(props) {

    // do some destructuring
    const { pageContext } = props;
    const classes = useStyles();
    const { humanPageNumber, numberOfPages, category } = pageContext;
    // console.log('pageContext in paginationLinks', pageContext);
    const pageNumbers = getPaginationNeighbors(humanPageNumber, numberOfPages);
    // console.log('pageNumbers in paginationLinks', pageNumbers);

    const basePath = category
        ? `/${category}/`
        : `/`;

    return (
        <div className={classes.links}>
            {humanPageNumber > 2 && <Link className={classes.link} to={`${basePath}`}>1</Link>}

            {pageNumbers.map(({ val, key }) => {
                const classNames = `
                ${classes.link} 
                ${val === humanPageNumber ? classes.currentPage : ''}
                ${val === '...' ? classes.ellipsis : ''}
                `;

                return (Number.isInteger(val) && val !== humanPageNumber
                        ? <Link key={key} className={classNames}
                                to={`${basePath}${val === 1 ? '' : val}`}>{val}</Link>
                        : <span key={key} className={classNames}>{val}</span>
                );
            })
            }

            {humanPageNumber < numberOfPages - 1 &&
            <Link className={classes.link} to={`${basePath}${numberOfPages}`}>{numberOfPages}</Link>}
        </div>
    );

}

PaginationLinks.propTypes = {
    pageContext: PropTypes.object.isRequired,
};

export default memo(PaginationLinks);