import React, { Fragment } from 'react';
import Post from './post/post';
import Content from './content';

import { makeStyles } from '@material-ui/styles';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
    ol: {
        listStyle: `none`,
        margin: 0,
        padding: 0,

        "& > li:not(:last-child)": {
            marginBottom: theme.spacing(2.5)
        },

        [theme.breakpoints.up(`sm`)]: {
            "& > li:not(:last-child)": {
                marginBottom: theme.spacing(4)
            },
        },

        [theme.breakpoints.up(`md`)]: {
            "& > li:not(:last-child)": {
                marginBottom: theme.spacing(8)
            },
        },
    },
}));

export default function PostList(props) {
    const { posts } = props;
    const classes = useStyles();
    return (
        <Content>
            <ol className={classes.ol}>
                {posts.map(({ node }) => {
                    const { id, frontmatter, fields, code } = node;
                    return (<li key={id}><Post fields={fields} frontmatter={frontmatter} body={code.body}/></li>);
                })}
            </ol>
        </Content>
    );
}

